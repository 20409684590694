/*Global Vars*/
:root {
    --primary-color: #265479;
    --secondary-color: #498a95;
    --complementary-color: #b6d5e9;
}

/***************************
       Custom Fonts
****************************/

@font-face {
    font-family: 'Roboto Bold';
    src: url('~/static/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('~/static/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto Thin';
    src: url('~/static/fonts/Roboto/Roboto-Thin.ttf');
}

/***************************
      End Custom Fonts
****************************/

/***************************
    Local styles
****************************/

html {
    -ms-overflow-style: scrollbar !important;
    height: 100% !important;
}

body {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42857143;
    height: 100% !important;
    width: 100%;
    margin: auto;
    min-width: 600px;
    max-width: 3840px;
    background-color: #f4f4f4;
}

a {
    color: var(--secondary-color);
}

a:hover {
    color: var(--complementary-color);
}

::-ms-clear {
    display: none;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

/***************************
    End local styles
****************************/

/**********************
    Ant design Overrides
***********************/

.ant-tree li .ant-tree-node-content-wrapper {
    color: black;
    font-size: 14px;
}

.ant-input-disabled {
    color: rgba(0, 0, 0, 0.55);
}

.ant-select-selection--single {
    height: 30px;
}

.ant-select-selection__rendered {
    line-height: 28px;
}

.ant-select-dropdown {
    z-index: 1450;
}

/**********************
    End Ant design Overrides
***********************/

/**********************
    Kendo Overrides
***********************/

.k-calendar-container {
    z-index: 10001 !important;
}

/**********************
    End Kendo Overrides
***********************/

/**********************
    Toastify Overrides
***********************/

.Toastify__toast--success {
    background: #0f7a12;
}

/**********************
    End Toastify Overrides
***********************/

/**********************
    Busy Spinner
***********************/

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0,
        rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0,
        rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**********************
   End Busy Spinner
***********************/
